<template>
  <div class="action-header">
    <div class="row">
      <div class="col-12 d-flex justify-content-end">
        <b-button v-b-modal.actionCreationModal variant="default" class="btn btn-outline-secondary">
          <i class="bi bi-pencil-square "></i> Create Action
        </b-button>
        {{ form.name }}
        <b-modal
          size="md"
          hide-backdrop
          ok-only
          header-bg-variant="light"
          footer-bg-variant="light"
          ok-title="Create action"
          no-close-on-ok
          ok-variant="secondary"
          @ok="create"
          id="actionCreationModal"
          title="Action Creation"
        >
          <b-container fluid>
            <b-row class="my-1 mb-2">
              <b-col sm="12">
                <label for="input-small">Name 
                  <i 
                  class="bi bi-info-circle" 
                  v-b-popover.hover.topright="'the name of the action is used to map action from the intent.'" 
                  title="Action Name"></i>
                </label>
                <b-form-input
                  id="input-small"
                  size="sm"
                  class="mt-2"
                  v-model="form.name"
                  placeholder="Action name (e.g vehicle.register)"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="my-1 mb-2">
              <b-col sm="12">
                <label for="input-small">Service <i 
                  class="bi bi-info-circle" 
                  v-b-popover.hover.topright="'The name of the service is used to map service from the intent.'" 
                  title="Action Service"></i></label>
                <b-form-input
                  id="input-small"
                  size="sm"
                  class="mt-2"
                  v-model="form.service"
                  placeholder="Service name (e.g vehicle)"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="my-1 mb-2">
              <b-col sm="12">
                <label for="input-small">Method <i 
                  class="bi bi-info-circle" 
                  v-b-popover.hover.topright="'The name of the method used to perform the operation on the service.'" 
                  title="Action Method"></i></label> 
                <b-form-input
                  id="input-small"
                  size="sm"
                  class="mt-2"
                  v-model="form.method"
                  placeholder="Method name (e.g register)"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="my-1 mb-2">
              <b-col sm="12">
                <label for="input-small">Status <i class="bi bi-info-circle" 
                  v-b-popover.hover.topright="'The status of the action determines wether or not the action is active or suspended.'" 
                  title="Action Status"></i></label>
                  <b-form-select 
                    v-model="form.status"
                    class="mt-2"
                    :options="options">
                  </b-form-select>
              </b-col>
            </b-row>

            <b-row class="my-1 mb-2">
              <b-col sm="12">
                <label for="input-small">Keywords <small class="text-muted"> (optional)</small> <i 
                  class="bi bi-info-circle" 
                  v-b-popover.hover.topright="'Comma separated list of keywords names'" 
                  title="Action Keywords"></i></label> 
                <b-form-input
                  id="input-small"
                  size="sm"
                  class="mt-2"
                  v-model="form.keywords"
                  placeholder="Keywords list (e.g time, current, date )"
                ></b-form-input>
              </b-col>
            </b-row>
          </b-container>
        </b-modal>
      </div>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
const ActionModule = createNamespacedHelpers('action')

export default {
  name: "ActionsHeader",
  data(){
    return {
      form: {
        name: null,
        status: null,
        method: null,
        service: null,
        keywords: null,
      },
      options: [
        { value: null, text: 'Select action status'},
        { value: 'active', text: 'Active'},
        { value: 'suspended', text: 'Suspended'},
      ]
    }
  },
  methods: {
    ...ActionModule.mapActions(['createAction']),

    async create(){
      let { name, keywords, method, service, status } = this.form
      if(keywords && keywords.length){
        keywords = keywords.split(',')
      }
      const payload = {
        name,
        status,
        method,
        service,
        keywords,
      }
      await this.createAction(payload)
    }
  },
};
</script>
