<template>
  <div class="table-wrapper">
    <div
      class="table-responsive card-table mt-4">
      <b-list-group class="list-group-flush list-group-light">
        <b-list-group-item class="row">
          <div class="col-12">
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="search-icon">
                    <i class="bi bi-search"></i>
                  </span>
                </div>
                <input
                  type="text"
                  class="form-control search-input"
                  placeholder="Search intents"
                  aria-label="intentes"
                  aria-describedby="search-icon"
                  v-model="table.searchTerm"
                  @input="handleIntentSearch"
                />
              </div>
            </div>
          </div>
        </b-list-group-item>

        <div v-if="!table.isLoading && intents.length">
          <b-list-group-item style="background-color: #f2f2f2">
            <div class="row header-title" style="text-align: left">
              <div class="col">DISPLAY NAME</div>
              <div class="col">FALLBACK</div>
              <div class="col">ACTION</div>
              <div class="col">NAME</div>
            </div>
          </b-list-group-item>

          <a
            href="javascript:void(0)"
            class="list-group-item list-group-item-action"
            v-for="(intent) in intents"
            :key="intent._id">
            <div
              class="row d-flex justify-content-center align-items-center flex-wrap"
              style="text-align: left">
              <div class="col-md-3 col-sm-12 text-capitalize text-truncate">
                {{ intent.displayName }}
              </div>
              <div class="col-md-3 col-sm-12 text-capitalize text-truncate">
                {{ intent.isFallback }}
              </div>
              <div class="col-md-3 col-sm-12 text-capitalize text-truncate">
                {{ intent.action || "------" }}
              </div>
              <div class="col-md-3 col-sm-12 text-capitalize text-truncate">
                {{ intent.name }}
              </div>
            </div>
          </a>
        </div>

        <!-- no intent found -->
          <NoResultsFound 
            v-if="!table.isLoading && !intents.length" 
            message="No record found. try changing the search criteria."/>
        <!-- no intent found -->

        <b-list-group-item class="pagination-section">
          <div>
            <CustomPagination 
              :count="count"
              :selectorText="'Intents per page:'"
              :recordsPerPage="table.recordsPerPage"
              @onPageChanged="handleOnPageChange"/>
          </div>
        </b-list-group-item>
      </b-list-group>
    </div>

    <!-- loadinf state start -->
    <div
      v-if="table.isLoading"
      class="d-flex justify-content-center flex-column align-items-center"
      style="margin-top: 25%">
      <div class="spinner-border text-primary mb-2" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div>
        Loading Intents
      </div>
    </div>
    <!-- loadinf state end -->
  </div>
</template>

<script>
import { debounce } from "lodash"
import NoResultsFound from "../pages/NoResultsFound.vue";
import CustomPagination from '../paginations/CustomPagination.vue'
import { createNamespacedHelpers } from "vuex";

const IntentModule = createNamespacedHelpers('intent');

export default {
  name: "IntentTable",
  components: {
    NoResultsFound,
    CustomPagination
  },
  data() {
    return {
      table: {
        isLoading: false,
        searchTerm: null,
        recordsPerPage: 10,
      },
    };
  },
  computed: {
    ...IntentModule.mapGetters(["selectAllIntents", 'selectIntentCount']),

    count(){
      return this.selectIntentCount
    },
    intents() {
      return this.selectAllIntents
    },
  },
  methods: {
    ...IntentModule.mapActions(["loadNextPage", "searchIntents", 'fetchIntentCount']),

    handleIntentSearch: debounce(async function(event) {
      this.table.isLoading = true;
      await this.searchIntents(event.target.value)
      this.table.isLoading = false;
    }, 500),

    handleOnPageChange: debounce(async function(event) {
      const { start, end } = event
      await this.loadNextPage({
        startIndex: start,
        endIndex: end,
      });
    })
  },
  async mounted() {
    this.table.isLoading = true;
    await this.fetchIntentCount()
    await this.loadNextPage({ startIndex: 0, endIndex: 10 });
    this.table.isLoading = false;
  },
};
</script>
<style lang="scss">
.list-group {
  border-radius: 5px;
}
.list-group-item {
  padding: 15px;
  font-size: 13px;
}
.header-title div {
  font-size: 12px;
  letter-spacing: 0.04em;
  border-bottom-width: 1px;
}
.table-responsive {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.search-input {
  outline: none;
  border: none !important;
  height: 55px;
  margin-left: -9px !important;
}
.form-control:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}
#search-icon {
  margin-top: 9px;
  font-size: 20px;
  border: none !important;
  background-color: white !important;
}
.pagination-section {
  padding: 10px !important;
  background-color: #f2f2f2;
}
</style>
