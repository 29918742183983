<template>
  <div class="row">
    <div class="col-12 mt-4">
      <div class="text-center text-muted">
        <p>{{ message }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: true,
    }
  },
  name: 'NoResultsFound',
}
</script>