<template>
  <div class="main-content-wrapper" :style="settings.fg">
    <IntentTable v-if="activePage === 'intent'"/>
    <ActionsPage v-if="activePage === 'actions'"/>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import StorageUtil from '../../../../lib/storage-util'
import ActionsPage from '../../pages/ActionPage.vue'
import IntentTable from '../../tables/IntentsTable.vue'
import { createNamespacedHelpers } from 'vuex'

const HyacinthModule = createNamespacedHelpers('hyacinth')
const SettingsModule = createNamespacedHelpers("setting")

export default {
  name: 'MainContent',
  components: {
    ActionsPage,
    IntentTable
  },
  data(){
    return{
      activePage: null,
    }
  },
  computed: {
    ...HyacinthModule.mapGetters(['selectCurrentPage']),
    ...SettingsModule.mapGetters(["selectUserSettings"]),

    settings() {
      return this.selectUserSettings;
    },
  },
  watch: {
    selectCurrentPage: debounce(async function(value){
      this.activePage = value;
    })
  },
  methods: {
    ...HyacinthModule.mapActions(['setCurrentPage']),

  },
  mounted() {
    const current = StorageUtil.read('currentPage')
    if(current){
      this.activePage = current
      this.setCurrentPage(this.activePage)
    }
    else {
      this.activePage = 'intent'
      this.setCurrentPage('intent')
    }
  }
}
</script>