<template>
  <div class="action-page">
    <div class="action-header mt-3 ">
      <ActionsHeader />
    </div>
    <div class="action contents">
      <ActionsTable/>
    </div>
  </div>
</template>
<script>
import ActionsTable from '../tables/ActionsTable.vue'
import ActionsHeader from '../modules/actions/ActionsHeader.vue'

export default {
  name: 'ActionPage',
  components: {
    ActionsTable,
    ActionsHeader,
  }
}
</script>