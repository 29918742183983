<template>
  <ion-page>
    <ion-header>
      <ion-toolbar></ion-toolbar>
    </ion-header>
    <ion-content :style="settings.fg">
      <div class="content-wrapper container-fluid">
        <div class="">
          <div class="row">
            <div class="col-md-2 left-aside " :style="settings.fg">
              <LeftAside />
            </div>
            <div class="col-md-10 centere-content text-center" :style="settings.fg">
              <MainContent />
            </div>
            <!-- <div class="col-md-2 right-aside text-center" :style="settings.fg">
              <p>Right Side</p>
            </div> -->
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import router from "@/router";
import LeftAside from "../modules/hyacinthv2/LeftAside.vue";
import MainContent from "../../components/modules/hyacinthv2/MainContent.vue"
import { mapActions, createNamespacedHelpers } from "vuex";
import { IonPage, IonContent, IonToolbar, IonHeader } from "@ionic/vue";

const SettingsModule = createNamespacedHelpers("setting");

export default {
  name: "Notification",
  components: {
    IonPage,
    IonHeader,
    IonContent,
    LeftAside,
    MainContent,
    IonToolbar,
  },
  data() {
    return {};
  },
  computed: {
    ...SettingsModule.mapGetters(["selectUserSettings"]),

    settings() {
      return this.selectUserSettings;
    },
  },
  methods: {
    ...mapActions(["changePageTitle", "navigateToOption"]),

    async setPageTitle() {
      await this.changePageTitle(router.currentRoute._value.name);
    },
  },
};
</script>
<style scoped lang="scss">
#container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 12%;
  transform: translateY(-50%);
}
</style>
