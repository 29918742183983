<template>
  <div :style="settings.fg">
    <nav
      class="navbar navbar-vertical fixed-start navbar-expand-md navbar-light"
      style="position: relative; border-width: 0;">
      <!-- Toggler -->
      <button
        class="navbar-toggler mb-3"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarVertical"
        aria-controls="navbarVertical"
        aria-expanded="false"
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      
      <!-- Collapse -->
      <div class="collapse navbar-collapse" id="navbarVertical">
        <!-- Navigation -->
        <ul class="navbar-nav mb-md-3 d-flex flex-column">
          <li class="nav-item">
            <div class="row mb-4">
              <div class="col-md-2">
                <!-- <ion-icon class="hyacinth-icon text-muted mt-2" :icon="layersOutline">
                </ion-icon> -->
              </div>
              <div class="col-md-10">
                <div class="navbar-brand" :style="settings.fg">
                  Hyacinth
                  <h6 class="card-subtitle text-muted">
                    Essentials
                  </h6>
                </div>
              </div>
            </div>
          </li>
          <li 
          class="nav-item" 
          v-for="upper in upperMenu" 
          :key="upper.id" 
          @click.prevent="changePage(upper.action, 'upper')">
            <a class="nav-link" href="javascript:void(0);" :style="upper.active ? activeClass : ''">
              <ion-icon class="item-icons text-muted" :icon="upper.icon"> </ion-icon>{{ upper.title }}
            </a>
          </li>
        </ul>
      </div>
    </nav>

    <hr>

    <nav
      class="navbar navbar-vertical fixed-start navbar-expand-md navbar-light"
      style="position: relative; border-width: 0;">
      <!-- Collapse -->
      <div class="collapse navbar-collapse" id="navbarVertical">
        <!-- Navigation -->
        <ul class="navbar-nav mb-md-3 d-flex flex-column">
          <li 
          class="nav-item" 
          v-for="lower in lowerMenu" 
          :key="lower.id" 
          @click.prevent="changePage(lower.action, 'lower')">
            <a :style="lower.active ? activeClass : ''" class="nav-link" href="javascript:void(0);">
              <ion-icon class="item-icons text-muted" :icon="lower.icon"> </ion-icon>{{ lower.title }}
            </a>
          </li>
        </ul>
      </div>
      <hr>
    </nav>
  </div>
</template>
<script>
import { 
  layersOutline, 
   chatboxEllipsesOutline,
   flashOutline,
   albumsOutline, schoolOutline, 
   checkboxOutline, timeOutline, 
   statsChartOutline, expandOutline 
} from "ionicons/icons"
import { IonIcon } from '@ionic/vue'
import { v4 as uuidv4 } from "uuid"
import { createNamespacedHelpers } from "vuex"
const HyacinthModule = createNamespacedHelpers('hyacinth')
const SettingsModule = createNamespacedHelpers("setting");

export default {
  name: "LeftAside",
  components: {
    IonIcon
  },
  data(){
    return {
      upperMenu: [{
        id: uuidv4(),
        icon: chatboxEllipsesOutline,
        title: 'Intents',
        action: 'intent',
        active: false,
      },
      {
        id: uuidv4(),
        icon: flashOutline,
        title: 'Actions',
        action: 'actions',
        active: false,
      },
      {
        id: uuidv4(),
        icon: albumsOutline,
        title: 'Entities',
        action: 'entities',
        active: false,
      },
      {
        id: uuidv4(),
        icon: schoolOutline,
        title: 'Training',
        action: 'training',
        active: false,
      }],

      lowerMenu: [{
        id: uuidv4(),
        icon: timeOutline,
        title: 'History',
        action: 'history',
        active: false,
      }, 
      {
        id: uuidv4(),
        icon: statsChartOutline,
        title: 'Analysis',
        action: 'analysis',
        active: false,
      },
      {
        id: uuidv4(),
        icon: checkboxOutline,
        title: 'Validation',
        action: 'validation',
        active: false,
      }]
    }
  },
  setup(){
    return {
      timeOutline,
      albumsOutline,
      expandOutline,
      layersOutline,
      schoolOutline,
      checkboxOutline,
      flashOutline,
      statsChartOutline,
      chatboxEllipsesOutline,
    }
  },

  computed: {
    ...HyacinthModule.mapGetters(['selectCurrentPage']),
    ...SettingsModule.mapGetters(["selectUserSettings"]),

    settings() {
      return this.selectUserSettings;
    },
    currentPage() {
      return this.selectCurrentPage
    },
    activeClass(){
      const { border, ...rest} = this.settings.fg
      return {
        rest,
        borderRadius: '0px',
        borderLeft: '2px solid #4db8ff',
        borderBottom: '2px solid #4db8ff',
      }
    }
  },
  methods: {
    ...HyacinthModule.mapActions(['setCurrentPage']),

    changePage(page, section){
      this.setCurrentPage(page)
      switch(section) {
        case 'lower':
          this.upperMenu = this.upperMenu.map(upper => { 
            return { ...upper, active: false }
          })
          this.lowerMenu = this.lowerMenu.map(lower => {
            if(lower.action === page){
              return {
                ...lower,
                active: true
              }
            }
            else {
              return {
                ...lower,
                active: false
              }
            }
          })
          break
        case 'upper':
          this.lowerMenu = this.lowerMenu.map(lower => {
            return { ...lower, active: false }
          })
          this.upperMenu = this.upperMenu.map(upper => {
            if(upper.action === page){
              return {
                ...upper,
                active: true
              }
            }
            else { 
              return {
                ...upper,
                active: false
              }
            }
          })
          break
        default:
          return
      }
    }
  },
  mounted() {
    if(this.currentPage){
      const section = this.upperMenu
        .find(upper => upper.action === this.currentPage)

      if(section) this.changePage(this.currentPage, 'upper')
      if(!section) this.changePage(this.currentPage, 'lower')
    }
  }
};
</script>
<style scoped>
  .hyacinth-icon{
    font-size: 40px;
  }
  .item-icons {
    font-size: 24px;
    margin-right: 15px;
    margin-bottom: -8px;
  }
  @media screen and (max-width: 768px) {
    .expand-btn {
      margin-left: 280px;
    }
  }
</style>

