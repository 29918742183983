<template>
  <div 
    style="font-size: 15px;" 
    class="d-flex flex-row align-items-center justify-content-end">

    <div style="margin-right: 10px;">
      <span for="rowPerPage">{{ selectorText }} </span>
    </div>

    <div 
       style="margin-right: 15px;"
       class="form-group ml-3">
      <select
        v-model="itemsPerPage"
        @input="handlePageRowsChange"
        class="form-select"
        aria-label="intent pagination">
        <option value="10" selected>10</option>
        <option value="15">15</option>
        <option value="20">20</option>
        <option value="All">All</option>
      </select>
    </div>

    <div class="mt-3">
      <b-pagination
        align="left"
        :per-page="rowsPerPage"
        :total-rows="count"
        first-number
        last-number
        v-model="currentPage"
        @page-click="handlePageChange">
        <template #prev-text>
          <i class="bi bi-arrow-left"></i>
        </template>
        <template #next-text>
          <i class="bi bi-arrow-right"></i>
        </template>
      </b-pagination>
    </div>
  </div>
</template>

<script>
import { debounce } from "lodash";

export default {
  name: "CustomPagination",
  props: {
    count: {
      type: Number,
    },
    recordsPerPage: {
      type: Number,
      default: 10,
    },
    selectorText: {
      type: String,
      default: "Results per page:"
    }
  },
  data() {
    return {
      currentPage: 1,
      rowsPerPage: null,
      itemsPerPage: null,
    };
  },
  methods: {
    calculateStartAndEndIndex(page) {
      let endIndex = page * this.rowsPerPage -1
      let startIndex = endIndex - this.rowsPerPage
      if(startIndex < 0){
        startIndex = 0
      }
      if(endIndex > this.count){
        endIndex = this.count -1
      }
      return { start: startIndex, end: endIndex }
    },

    handlePageChange: debounce(async function(event, page) {
      event.preventDefault();
      const { start, end } = this.calculateStartAndEndIndex(page)
      this.$emit('onPageChanged', { start, end })
    }),

    handlePageRowsChange: debounce(async function(event) {
      const value = event.target.value;
      if (value === "All") {
        this.rowsPerPage = this.count
        this.$emit('onPageChanged', { start: 0, end: this.rowsPerPage })
      } else {
        this.rowsPerPage = parseInt(value)
        this.$emit('onPageChanged', { start: 0, end: this.rowsPerPage })
      }
    }, 300),
  },
  mounted() {
    if(this.recordsPerPage){
      this.rowsPerPage = this.recordsPerPage
      this.itemsPerPage = this.recordsPerPage
    }
  }
};
</script>

<style lang="scss" scoped></style>
